<template>
    <div class="w-100">
        <vue-headful :title="$t('title_session_data')" description="title" />
        <TabSelector class="mt-6" />
        <router-view />
    </div>
</template>

<script>
import TabSelector from '@/components/sessions/TabSelector.vue'

export default {
    name: 'SessionData',
    components: {
        TabSelector,
    },
}
</script>

<style scoped></style>
