<template>
    <v-row>
        <v-tabs
            v-model="tab"
            class="mb-10"
            height="40"
            slider-size="6"
            slider-color="#FF6F3A"
            id="tabs"
            @change="goToRoute()"
        >
            <v-tab
                v-for="item in items"
                :key="item"
                class="no-uppercase text-subtitle-1 tab-items"
                active-class="font-weight-black black--text"
            >
                {{ item }}
            </v-tab>
        </v-tabs>
    </v-row>
</template>

<script>

export default {
    data() {
        return {
            tab: 0,
        }
    },
    mounted() {
        this.cleanTabBackgroundColor()
        this.checkRoute()
    },
    computed: {
        items() {
            return [
                this.$t('sessions.sessions'),
                this.$t('sessions.my_students'),
            ]
        },
    },
    methods: {
        checkRoute() {
            if (this.$router.history.current.path === '/teacher/sessions/students') {
                this.tab = 1
            }
        },
        cleanTabBackgroundColor() {
            const elementActiveUsers = document.getElementById('tabs')
            elementActiveUsers.classList.remove('theme--light')
        },
        goToRoute() {
            if (this.tab === 0) {
                this.$router.push('/teacher/sessions/list')
            } else {
                this.$router.push('/teacher/sessions/students')
            }
        },
    },
}
</script>

<style scoped>
.no-uppercase {
     text-transform: none;
}
.v-tabs-bar {
    height: 80px !important;
}
.v-tabs-bar__content {
    height: 80px !important;
}
.v-tabs-slider-wrapper {
    height: 5px;
}
.tab-items {
    border-bottom: 6px solid #FFE1D7;
    margin-left: 10px;
    margin-right: 10px;
}
</style>
